export default function text2svg(text = "", color) {
  const char = unescape(encodeURIComponent((text + "").charAt(0)));
  return (
    "data:image/svg+xml;base64," +
    btoa(`<svg width="40" height="40" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <rect width="100%" height="100%" fill="${color || "#ccc"}"></rect>
      <g transform="translate(10,10)">
        <text font-size="10px" fill="#fff" text-anchor="middle" dominant-baseline="central">${char}</text>
      </g>
    </svg>`)
  );
}
