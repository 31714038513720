import Layout from "@/Layout";
import { Module } from "./interceptor";

export default [
  {
    path: "/datamodel",
    component: Layout,
    meta: { title: "视图", checkSpace: true },
    beforeEnter: Module.datamodel,
    children: [
      {
        path: "",
        name: "datamodel",
        redirect: { name: "datamodel-welcome" },
        component: () =>
          import(
            /* webpackChunkName: "datamodel-entry" */ "@/views/datamodel/Entry.vue"
          ),
        children: [
          {
            path: "welcome",
            name: "datamodel-welcome",
            component: () =>
              import(
                /* webpackChunkName: "datamodel-welcome" */ "@/views/datamodel/Welcome.vue"
              )
          },
          {
            path: "workspace/:workspaceId/guide",
            name: "datamodel-workspace-guide",
            component: () =>
              import(
                /* webpackChunkName: "datamodel-guide" */ "@/views/datamodel/WorkspaceGuide.vue"
              )
          },
          {
            path: "workspace/:workspaceId/:datasheetId",
            name: "datamodel-workspace",
            component: () =>
              import(
                /* webpackChunkName: "datamodel-workspace" */ "@/views/datamodel/Workspace.vue"
              )
          }
        ]
      }
    ]
  }
];
