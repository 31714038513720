import store from "../store";
import PRIVILEGES from "@/constant/privileges";
import PERMISSIONS from "@/constant/permissions";

export const User = {
  // 超级管理员权限
  [PRIVILEGES.SUPER_ADMIN](to, from, next) {
    if (store.isSuperAdmin) {
      next();
    } else {
      next({ name: "error-403" });
    }
  },
  // 主管理员权限
  [PRIVILEGES.MAIN_ADMIN](to, from, next) {
    const userInfo = store.userInfo;
    if (!userInfo.isMainAdmin) return next({ name: "error-403" });
    next();
  },
  // 组管理员权限
  [PRIVILEGES.SUB_ADMIN](to, from, next) {
    const userInfo = store.userInfo;
    if (!userInfo.isSubAdmin) return next({ name: "error-403" });
    next();
  },
  // 主管理员或组管理员权限
  [PRIVILEGES.ADMIN](to, from, next) {
    const userInfo = store.userInfo;
    const isAdmin = userInfo.isSubAdmin || userInfo.isMainAdmin;
    if (!isAdmin) return next({ name: "error-403" });
    next();
  }
};

export const Permission = {
  [PERMISSIONS.MANAGE_WORKBENCH](to, from, next) {
    const permissionMap = store.permissionMap;
    if (!permissionMap[PERMISSIONS.MANAGE_WORKBENCH]) {
      return next({ name: "error-403" });
    }
    next();
  },
  [PERMISSIONS.MANAGE_MEMBER](to, from, next) {
    const permissionMap = store.permissionMap;
    const hasPermission =
      permissionMap[PERMISSIONS.MANAGE_MEMBER] ||
      permissionMap[PERMISSIONS.MANAGE_TEAM];
    if (!hasPermission) return next({ name: "error-403" });
    next();
  },
  [PERMISSIONS.MANAGE_NORMAL_MEMBER](to, from, next) {
    const permissionMap = store.permissionMap;
    if (!permissionMap[PERMISSIONS.MANAGE_NORMAL_MEMBER]) {
      return next({ name: "error-403" });
    }
    next();
  }
};
Permission[PERMISSIONS.MANAGE_TEAM] = Permission[PERMISSIONS.MANAGE_MEMBER];

export const Module = {
  connections(to, from, next) {
    if (!store.enableModules.connections) {
      return next({ name: "error-403" });
    }
    next();
  },
  datamodel(to, from, next) {
    if (!store.enableModules.datamodel) {
      return next({ name: "error-403" });
    }
    next();
  }
};

export default { User, Permission, Module };
