<template>
  <a-result status="403" title="403" sub-title="无访问权限">
    <template #extra>
      <div class="ndl-margin-bottom-lg">
        <a-button type="primary" @click="home()">
          回到工作台
        </a-button>
      </div>
      <a @click="back()"><i class="ndl-icon-arrow-left"></i> 返回上一步</a>
    </template>
  </a-result>
</template>

<script>
import pic404 from "@/assets/images/ai_error_404.svg";

export default {
  data() {
    return { pic404 };
  },
  methods: {
    home() {
      this.$router.push("/");
    },
    back() {
      history.back();
    }
  }
};
</script>
