import Avatar from "./src/Avatar";

const colors = [
  "#d8c292",
  "#5b5b5b",
  "#b67171",
  "#c19065",
  "#98ded9",
  "#ff9292",
  "#ffb4b4",
  "#ffe227",
  "#845ec2",
  "#ffc75f",
  "#ff5e78",
  "#350b40",
  "#75cfb8",
  "#eb5e0b",
  "#c19277",
  "#a6f0c6",
  "#493323",
  "#91684a",
  "#eaac7f",
  "#ffdf91",
  "#252525",
  "#ec4646",
  "#663f3f",
  "#51c2d5",
  "#4d375d",
  "#252525",
  "#6930c3",
  "#00af91",
  "#ef4f4f",
  "#654062",
  "#83a95c",
  "#ff4646",
  "#41584b",
  "#db6400",
  "#61b15a",
  "#f05454",
  "#f6830f"
];

export const colorOf = id => {
  const str = String(id || "");
  let sumCode = 0;
  for (let i = 0, len = str.length; i < len; i += 1) {
    sumCode += str.charCodeAt(i);
  }
  return colors[sumCode % colors.length];
};

Avatar.colors = colors;
Avatar.colorOf = colorOf;

export { default as text2svg } from "./src/text2svg";

export default Avatar;
