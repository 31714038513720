import Layout from "@/Layout";
import { Module } from "./interceptor";

export default [
  {
    path: "/connections",
    name: "connections",
    redirect: { name: "connections-overview-easy" },
    component: Layout,
    meta: { title: "连接", checkSpace: true },
    beforeEnter: Module.connections,
    children: [
      // 连接首页 - 主库模式
      {
        path: "overview-easy",
        name: "connections-overview-easy",
        component: () =>
          import(
            /* webpackChunkName: "connections" */ "@/views/connections/OverviewEasy.vue"
          )
      },
      // 连接首页 - 普通模式
      {
        path: "overview",
        name: "connections-overview",
        component: () =>
          import(
            /* webpackChunkName: "connections" */ "@/views/connections/Overview.vue"
          )
      },
      // 连接器详情页
      {
        path: "connector/:connectorId",
        name: "connector-details",
        component: () =>
          import(
            /* webpackChunkName: "connections" */ "@/views/connections/ConnectorDetailsEntry.vue"
          )
      },
      // 连接器 definitions
      {
        path: "definition/directory/:category",
        name: "definition-directory",
        component: () =>
          import(
            /* webpackChunkName: "connections" */ "@/views/connections/DefinitionDirectory.vue"
          )
      },
      // 新建数据源连接器 - 普通模式
      {
        path: "connector/new/:definitionId",
        name: "connector-creation",
        component: () =>
          import(
            /* webpackChunkName: "connections" */ "@/views/connections/ConnectorCreation.vue"
          )
      },
      // 新建数据源连接器 主库模式
      {
        path: "connector/new-easy/creating",
        name: "connector-creation-easy",
        component: () =>
          import(
            /* webpackChunkName: "connections" */ "@/views/connections/ConnectorEasyCreation.vue"
          )
      },
      // 新建连接
      {
        path: "connection/new",
        name: "connection-creation",
        component: () =>
          import(
            /* webpackChunkName: "connections" */ "@/views/connections/ConnectionCreation.vue"
          )
      },
      // 连接详情
      {
        path: "connection/:connectionId",
        name: "connection-editor",
        component: () =>
          import(
            /* webpackChunkName: "connections" */ "@/views/connections/ConnectionEditor.vue"
          )
      }
    ]
  }
];
