// 定义了用户身份类型
export default Object.freeze({
  SUPER_ADMIN: -1,
  MAIN_ADMIN: 0,
  SUB_ADMIN: 1,
  // isMainAdmin or isSubAdmin
  ADMIN: 2,
  // 普通用户
  NORMAL: 3
});
