<template>
  <section class="ndl-flex ndl-flex--column ndl-flex--center ndl-flex--middle">
    <img :src="pic404" style="max-width: 80vw; max-height: 380px" />
    <p class="ndl-text-desc ndl-padding-bottom-lg ndl-margin-bottom-lg">
      访问的页面不见了～
    </p>
    <a-button type="primary" style="width: 150px" @click="home()">
      <i
        class="ndl-icon-monitor ndl-margin-right-sm"
        style="position: relative; top: 1px"
      ></i>
      回到工作台
    </a-button>
    <a class="ndl-margin-top-lg" @click="back()">
      <i class="ndl-icon-arrow-left"></i> 返回上一步
    </a>
  </section>
</template>

<script>
import pic404 from "@/assets/images/ai_error_404.svg";

export default {
  data() {
    return { pic404 };
  },
  methods: {
    home() {
      this.$router.push("/");
    },
    back() {
      history.back();
    }
  }
};
</script>
