import Column from "../model/column";
import isString from "lodash/isString";

// cell renders
import CellRenderText from "../renders/CellContentText";
import CellRenderNumber from "../renders/CellContentNumber";
import CellRenderMail from "../renders/CellContentMail";
import CellRenderDate from "../renders/CellContentDate";
import CellRenderURL from "../renders/CellContentURL";
import CellRenderRadio from "../renders/CellContentRadio";

/**
 * @type {{
 *  text: "text",
 *  number: "number",
 *  date: "date",
 *  json: "json",
 *  function: "function",
 *  radio: "radio",
 *  mail: "mail",
 *  percent: "percent",
 *  tel: "tel",
 *  url: "url",
 *  currency: "currency"
 * }}
 */
export const Types = {};
export const Icon = {};
export const Label = {};
export const Model = {};
export const Render = {};

export function define(type = "", description = {}) {
  if (!type) throw new Error("Invalid column type");
  Types[type] = type;
  Icon[type] = description.icon;
  Label[type] = description.label;
  Render[type] = description.render;
  Model[type] = class extends Column {
    constructor(params, opts) {
      const column = isString(params) ? { columnName: params } : params;
      super(column, opts);
      this.columnType = type;
    }
  };
}

// 文本列
define("text", {
  label: "文本",
  icon: "ndl-icon-font-size",
  render: CellRenderText
});
// 数值列
define("number", {
  label: "数值",
  icon: "ndl-icon-hash",
  render: CellRenderNumber
});
// 日期列
define("date", {
  label: "日期",
  icon: "ndl-icon-calendar-time",
  render: CellRenderDate
});
// JSON列
define("json", {
  label: "JSON",
  icon: "ndl-icon2-json",
  render: CellRenderText
});
// 公式列
define("function", {
  label: "公式",
  icon: "ndl-icon-math-function",
  render: CellRenderText
});
define("radio", {
  label: "单选",
  icon: "ndl-icon-disc",
  render: CellRenderRadio
});
define("mail", {
  label: "邮箱",
  icon: "ndl-icon-at-sign",
  render: CellRenderMail
});
// 百分比
define("percent", {
  label: "百分比",
  icon: "ndl-icon-percent",
  render: CellRenderText
});
define("tel", {
  label: "电话",
  icon: "ndl-icon-phone",
  render: CellRenderText
});
// 网址
define("url", {
  label: "网址",
  icon: "ndl-icon-link",
  render: CellRenderURL
});
// 货币
define("currency", {
  label: "货币",
  icon: "ndl-icon-currency-dollar",
  render: CellRenderText
});

export default Types;
