<template>
  <div class="header ndl-flex ndl-flex--middle">
    <Avatar
      circle
      class="ndl-flex-item--noshrink ndl-margin-right"
      style="box-shadow: rgba(31, 35, 41, 0.1) 0px 4px 8px 0px"
      :border="false"
      :color="userInfo.memberId | colorOf"
      :src="userInfo.avatar"
      :text="userInfo.memberName"
    />
    <div class="ndl-flex ndl-flex--column">
      <div class="ndl-flex ndl-flex--middle">
        <span class="member ndl-ellipsis" :title="userInfo.memberName">
          {{ userInfo.memberName }}
        </span>
        <i class="ndl-icon-edit-line" @click="redirect()"></i>
      </div>
      <span class="space ndl-ellipsis" :title="userInfo.spaceName">
        {{ userInfo.spaceName }}
      </span>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Avatar, { colorOf } from "@/components/avatar";

export default {
  inheritAttrs: false,
  components: { Avatar },
  props: {
    hidePopover: Function
  },
  computed: {
    userInfo() {
      return store.userInfo || {};
    }
  },
  filters: { colorOf },
  methods: {
    redirect() {
      this.$router.push("/user/center");
      this.hidePopover();
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.header {
  background-color: @blue-5;
  background-image: linear-gradient(100deg, @primary-color, #23c9ff);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #fff;
  padding: 2.3em 1.5em;
  .member,
  .space {
    max-width: 150px;
  }
  .member {
    max-width: 150px;
    font-size: 18px;
  }
  .space {
    color: rgba(255, 255, 255, 0.65);
    margin-top: 4px;
  }
  .ndl-icon-edit-line {
    margin-left: 6px;
    cursor: pointer;
  }
}
</style>
