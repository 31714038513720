import Vue from "vue";
import router from "./router";
import App from "./App";
import "./antd";

// 样式
import "./assets/app.less";

Vue.config.productionTip = false;

// 移除 body 加载动画
router.onReady(() => {
  document.body.className = "";
});

new Vue({
  el: "#app",
  router,
  render: h => h(App)
});
