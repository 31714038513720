import clone from "lodash/cloneDeep";

export default class ColumnOption {
  constructor(options) {
    Object.assign(
      this,
      {
        // 数值列精度
        precision: null,
        // 默认为 undefined，不然相关控件不显示 placeholder
        dayFmt: void 0,
        // 格式化为百分比（公式列有用到此选项）
        formatAsPercentage: false,
        // 按文本类型排序（公式列有用到此选项）
        sortByText: false,
        // 单选列选项颜色的取色盘
        palette: [],
        // 公式列的公式
        formula: ""
      },
      options
    );
  }
  static from(options) {
    return new ColumnOption(clone(options));
  }
}
