<template>
  <span
    class="avatar-wrapper"
    :class="[size, { circle, border }]"
    :style="style"
  ></span>
</template>

<script>
import text2svg from "./text2svg";

export default {
  props: {
    // 图片地址
    src: String,
    // 没有图片时的文本
    text: {
      type: String,
      default: ""
    },
    // 用 text 生成字符图片时的背景色
    color: String,
    circle: Boolean,
    // mini small default large, huge
    size: {
      type: [String, Number],
      default: "default"
    },
    border: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    style() {
      const { src, text, color, size } = this;
      const avatarSize = isNaN(Number(size)) ? size : Number(size) + "px";
      return {
        backgroundImage: `url(${src || text2svg(text, color)})`,
        width: avatarSize,
        height: avatarSize
      };
    }
  }
};
</script>

<style lang="less" scoped>
.size(@n) {
  width: @n;
  height: @n;
}
.avatar-wrapper {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 4px;
  cursor: pointer;
  &.circle {
    border-radius: 100%;
  }
  &.border {
    border: solid 1px #e5e5e5;
  }
  // 尺寸
  &,
  &.default {
    .size(60px);
  }
  &.mini {
    .size(20px);
  }
  &.small {
    .size(40px);
  }
  &.large {
    .size(80px);
  }
  &.huge {
    .size(120px);
  }
}
</style>
