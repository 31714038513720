import Axios from "axios";
import { interceptor } from "./index";
import resolveURL from "@/utils/baseurl";

const axios = Axios.create({ baseURL: resolveURL("/api/space") });

interceptor.auth(axios);

export function createSpace(data) {
  return axios.post("/create", data);
}

export function updateSpace(data) {
  return axios.post("/update", data);
}
// 更新 logo、名称之类 ...
export function simpleUpdateSpace(data) {
  return axios.post("/simpleUpdate", data);
}

export function getSpace(spaceId) {
  return axios.get("/info/" + spaceId);
}

export function deleteSpace(spaceId) {
  return axios.delete("/delete/" + spaceId);
}

export function dropSpace(spaceId) {
  return axios.delete("/delete/now/" + spaceId);
}

export function revertSpace(data) {
  return axios.get("/recover", data);
}

export function listSpace(params) {
  return axios.get("/list", { params });
}

export function exitSpace(params) {
  return axios.get("/exit", { params });
}

export function deleteMemberFromSpace(data) {
  return axios.delete("/member/remove", { data });
}

export function uploadMemberFile(data) {
  return axios.post("/member/upload", data);
}

export function addSubAdmin(data) {
  return axios.post("/role/add", data);
}

export function updateSubAdmin(data) {
  return axios.post("/role/update", data);
}

export function deleteSubAdmin(memberId) {
  return axios.delete("/role/delete/" + memberId);
}

export function listSubAdmin() {
  return axios.get("/role/list");
}

export function changeMainAdmin(data) {
  return axios.post("/changeAdmin", data);
}

export function getSpaceInfoCount() {
  return axios.get("/spaceInfoCount");
}

export default axios;
