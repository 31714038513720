export default [
  // ⚠️ 优先定义 404 路由
  {
    path: "/share/404",
    name: "share-404",
    meta: { open: true },
    component: () =>
      import(
        /* webpackChunkName: "share-404" */ "@/views/share/error/Invalid.vue"
      )
  },
  {
    path: "/share/dashboard/:dashboardId",
    name: "share-dashboard",
    meta: { open: true },
    component: () =>
      import(
        /* webpackChunkName: "share-dashboard" */ "@/views/share/dashboard/Dashboard.vue"
      )
  }
];
