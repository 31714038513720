import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import localeData from "dayjs/plugin/localeData";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "dayjs/plugin/isMoment";
import mutable from "dayjs/plugin/badMutable";
import weekOfYear from "dayjs/plugin/weekOfYear";
import "dayjs/locale/zh";

dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(moment);
dayjs.extend(mutable);

dayjs.locale("zh");

export const isMoment = dayjs.isDayjs;

export default dayjs;
