import Layout from "@/Layout";

export default [
  {
    path: "/user/new_space",
    name: "new-space",
    meta: { title: "创建新空间" },
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/user/CreateSpace.vue")
  },
  {
    path: "/user/center",
    meta: { title: "用户中心" },
    component: Layout,
    children: [
      {
        path: "",
        name: "user-center",
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/user/UserCenter.vue")
      }
    ]
  },
  {
    path: "/auth/login",
    name: "login",
    meta: { title: "下秒数据", open: true },
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/auth/Login.vue")
  },
  {
    path: "/auth/reset",
    name: "reset-password",
    // open: true 标识该页面开放访问，路由鉴权时可直接放行
    meta: { title: "下秒数据", open: true },
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/auth/Reset.vue")
  },
  {
    path: "/invite",
    name: "invite",
    meta: { title: "邀请加入空间", open: true },
    component: () =>
      import(/* webpackChunkName: "public" */ "../views/invite/Index.vue")
  }
];
