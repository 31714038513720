export function random(n = 5) {
  let result = "";
  let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const len = chars.length;
  for (let i = 0; i < n; i++) {
    result += chars.charAt(Math.floor(Math.random() * len));
  }
  return result;
}

/**
 * 将数值索引序列化成字母。
 * 例如 0 => "A",  25 => 'Z', 26 => 'AA'。
 * @param {number} index
 * @returns {string}
 */
export function serializeIndex(index) {
  let divisor = index;
  // 商
  let quotient = Math.floor(divisor / 26);
  // 余数
  let remainder = divisor % 26;
  const arr = [remainder];
  while (quotient) {
    divisor = quotient;
    quotient = Math.floor(divisor / 26);
    remainder = divisor % 26;
    arr.unshift(remainder);
  }
  if (arr.length > 1) arr[0] = arr[0] - 1;
  return arr.map(code => String.fromCharCode(65 + code)).join("");
}

/**
 * 首字母大写
 * @param {string} str
 * @returns {string}
 */
export function capitalize(str) {
  str = String(str);
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
