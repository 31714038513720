<template>
  <div class="ndl-padding-lg ndl-padding-top">
    <div class="ndl-text-bold ndl-margin-bottom">个人信息</div>
    <div class="info">
      <div class="label">手机号码</div>
      <div class="value">{{ userInfo.mobile || "-" }}</div>
    </div>
    <div class="info">
      <div class="label">邮箱</div>
      <div class="value">{{ userInfo.mail || "-" }}</div>
    </div>
  </div>
</template>

<script>
import store from "@/store";

export default {
  inheritAttrs: false,
  computed: {
    userInfo() {
      return store.userInfo || {};
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.info {
  display: flex;
  font-size: 13px;
  padding: 5px 0;
  overflow: hidden;
  .label {
    flex-shrink: 0;
    width: 80px;
    color: @text-color-secondary;
  }
  .value {
    line-break: anywhere;
  }
}
</style>
