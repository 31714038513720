export default class User {
  uid = "";
  nickname = "";
  avatar = "";
  mobile = "";
  mail = "";
  memberId = "";
  memberName = "";
  // 是否空间主管理员
  isMainAdmin = false;
  // 是否空间子管理员
  isSubAdmin = false;
  spaceId = "";
  spaceName = "";
  spaceLogo = "";
  constructor(user) {
    Object.assign(this, user);
  }
  static from(user) {
    return new User(user);
  }
}
