<template>
  <main class="layout-wrapper">
    <aside :class="['layout-sidebar', navbarStates]">
      <!-- 用户头像 -->
      <UserPopover
        class="user-avatar"
        :user="userInfo"
        :get-popup-container="() => $el"
      />
      <!-- 导航菜单 -->
      <nav v-if="!spaceInfo.isPreDelete" class="nav-list">
        <router-link
          v-if="enableModules.connections"
          :to="{ name: 'connections' }"
          tag="li"
        >
          <span class="icon-button"><i class="ndl-icon-command"></i></span>
          <span class="nav-title">连接</span>
        </router-link>
        <router-link
          v-if="enableModules.datamodel"
          :to="{ name: 'datamodel' }"
          tag="li"
        >
          <span class="icon-button"><i class="ndl-icon-tv"></i></span>
          <span class="nav-title">视图</span>
        </router-link>
        <!-- 设置菜单仅管理员可见 -->
        <router-link
          v-if="userInfo.isMainAdmin || userInfo.isSubAdmin"
          :to="{ name: 'settings' }"
          tag="li"
        >
          <span class="icon-button"><i class="ndl-icon-settings"></i></span>
          <span class="nav-title">设置</span>
        </router-link>
      </nav>
      <span class="placeholder"></span>
      <!-- 用户中心 -->
      <!-- 帮助按钮 -->
      <a
        class="icon-button"
        href="https://ceh5v50tem.feishu.cn/wiki/space/7125714792941125635?ccm_open_type=lark_wiki_spaceLink"
        target="__blank"
      >
        <i class="ndl-icon-help-circle"></i>
      </a>
      <!-- 空间 logo -->
      <a-tooltip :title="spaceInfo.spaceName" placement="right">
        <Avatar
          size="36"
          class="space-logo"
          :color="spaceInfo.spaceId | colorOf"
          :border="false"
          :src="spaceInfo.spaceLogo"
          :text="spaceInfo.spaceName"
        />
      </a-tooltip>
    </aside>
    <article class="page-view">
      <router-view />
    </article>
  </main>
</template>

<script>
import store, { ui as uiState } from "@/store";
import Avatar, { colorOf } from "@/components/avatar";
import UserPopover from "@/components/user-popover";

export default {
  components: { Avatar, UserPopover },
  computed: {
    userInfo() {
      return store.userInfo || {};
    },
    spaceInfo() {
      return store.spaceInfo || {};
    },
    navbarStates() {
      return uiState;
    },
    enableModules() {
      return store.enableModules;
    }
  },
  filters: { colorOf },
  /**
   * 无 spaceId 时进入创建空间页面。
   * 无权限创建空间时该页面会显示相应提示信息。
   */
  beforeRouteEnter(to, from, next) {
    next(store.spaceId ? true : "/user/new_space");
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

@icon-button-size: 38px;

.layout-wrapper {
  height: 100vh;
  overflow: auto;
  display: flex;

  .icon-button {
    flex-shrink: 0;
    font-size: 20px;
    color: fade(@white, 85%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: @icon-button-size;
    height: @icon-button-size;
    cursor: pointer;
    transition: color 0.3s;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 100%;
      background: #fff;
      transform: scale(0);
      opacity: 0;
      transition: transform 0.3s, opacity 0.3s;
    }
  }
  .icon-button:hover,
  .nav-list li:hover .icon-button,
  .router-link-active .icon-button,
  .router-link-active.icon-button {
    color: @primary-color !important;
    &::after {
      transform: scale(1);
      opacity: 1;
    }
  }
  .page-view {
    flex-grow: 1;
    overflow: auto;
  }
}

/* 侧边栏 */
.layout-sidebar {
  width: @navbar-width;
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  padding: 10px 8px;
  align-items: center;
  flex-direction: column;
  user-select: none;
  // 比 workspace 的侧边栏 z-index 高一些，不然 box-shadow 会被遮住
  z-index: @datasheet-loading-mask-zindex + 5;
  background: #1f7efb;
  box-shadow: 0px 0px 15px -5px #1890ff;
  color: #fff;
  &.float {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    transition: transform 0.2s;
    transform: translateX(-110%);
  }
  &.float.visible {
    transform: translateX(0);
  }
}
/* 用户头像 */
.user-avatar {
  flex-shrink: 0;
  margin-bottom: 1em;
}
/* 占位元素，用于吸收剩余高度 */
.placeholder {
  flex: 1 1 0;
}

.space-logo {
  margin-top: 1em;
  flex-shrink: 0;
}

/* 导航菜单  begin */
.nav-list {
  flex-shrink: 0;
  list-style: none;
  li {
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
  }
  .nav-title {
    font-size: 12px;
    margin-top: 3px;
    display: inline-block;
  }
}
/* 导航菜单  end */
</style>
