import cache from "lscache";
import axios from "axios";
import JSON2FormData from "json-form-data";
import resolveURL from "./baseurl";
import ErrorCodes from "@/constant/error-code";

const TOKEN_TYPE = "auth.token_type";
const ACCESS_TOKEN = "auth.access_token";
const REFRESH_TOKEN = "auth.refresh_token";

export const token = {
  get refreshToken() {
    return cache.get(REFRESH_TOKEN);
  },
  get tokenType() {
    return cache.get(TOKEN_TYPE);
  },
  get accessToken() {
    return cache.get(ACCESS_TOKEN);
  },
  get defaultAuthorization() {
    return "Basic ZnJvbnRlbmQ6ZnJvbnRlbmQ=";
  },
  get existing() {
    return Boolean(token.accessToken && token.tokenType);
  },
  set({ token_type, access_token, refresh_token, expires_in }) {
    // ⚠️ lscache.set 过期时间单位为分钟
    const minute = Math.ceil(expires_in / 60);
    cache.set(TOKEN_TYPE, token_type, minute);
    cache.set(ACCESS_TOKEN, access_token, minute);
    cache.set(REFRESH_TOKEN, refresh_token);
    // expires_in 单位为秒，过期前 30s 自动刷新 token
    clearTimeout(token.__auto_refresh_timer__);
    token.__auto_refresh_timer__ = setTimeout(
      token.refresh,
      (expires_in - 30) * 1000
    );
  },
  clear() {
    cache.remove(TOKEN_TYPE);
    cache.remove(ACCESS_TOKEN);
    cache.remove(REFRESH_TOKEN);
  },
  value() {
    const token = cache.get(ACCESS_TOKEN);
    const type = cache.get(TOKEN_TYPE);
    if (token && type) return type + " " + token;
    return "";
  },
  /**
   * 更新所有 axios 实例的 headers(e.g. Authorization、x-space-id)。
   * axios 不能设置全局 headers，暂时利用 toString() 和闭包实现统一更新。
   */
  updateAxiosHeaders: (() => {
    let authorization = "";
    let xSpaceId = "";
    return function(spaceId) {
      authorization = token.value();
      if (spaceId) {
        xSpaceId = spaceId;
      }
      const headers = axios.defaults.headers.common || {};
      if (!headers["Authorization"]) {
        headers["Authorization"] = { toString: () => authorization };
      }
      if (!headers["x-space-id"]) {
        headers["x-space-id"] = { toString: () => xSpaceId };
      }
    };
  })(),
  // 重定向至登陆页面
  redirect() {
    const url = new URL("/auth/login", location);
    url.searchParams.append("referrer", location.href);
    location.href = url;
  },
  async refresh() {
    if (!token.refreshToken) {
      token.redirect();
      throw new Error("Invalid Refresh Token");
    }
    const refreshConfig = {
      url: resolveURL("/api/uua/auth"),
      method: "post",
      data: JSON2FormData({
        grant_type: "refresh_token",
        refresh_token: token.refreshToken
      }),
      headers: {
        Authorization: token.defaultAuthorization,
        "Content-Type": "multipart/form-data"
      }
    };
    try {
      const { data } = await axios(refreshConfig);
      token.set(data);
      token.updateAxiosHeaders();
    } catch (err) {
      const response = err.response;
      const data = (response && response.data) || {};
      if (data.code !== ErrorCodes.REFRESH_TOKEN_EXPIRED) {
        throw err;
      }
      token.clear();
      token.redirect();
    }
  },
  /**
   * 检查是否存在 token，不存在时尝试刷新 token。
   * 若刷新之后仍无法获取有效 token，则 reject promise;
   * 反之 token 存在或刷新后 token 有效，则 resolve promise。
   * @returns Promise<boolean>
   */
  async check() {
    if (token.existing) {
      return true;
    }
    if (token.refreshToken) {
      return token.refresh();
    }
    return Promise.reject(false);
  }
};

export default token;
