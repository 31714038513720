<script>
import { colors, uniqueColorBy } from "../config";

const cache = {
  styleOf(colorDefine) {
    if (!colorDefine) return null;
    const [bgcolor, fontcolor] = colorDefine || [];
    if (!fontcolor) {
      return `background-color: ${bgcolor};`;
    }
    return `background-color: ${bgcolor}; color: ${fontcolor}`;
  },
  get(value, options) {
    const palette = options.palette || [];
    const def = palette.find(item => item.value === value);
    let index = def && def.color;
    if (!index && index !== 0) {
      index = uniqueColorBy(value);
    }
    return cache.styleOf(colors[index]);
  }
};

export default {
  functional: true,
  render(h, context) {
    const props = context.props;
    const column = props.column;
    if (!props.value) return null;
    const style = cache.get(props.value, column.columnOptions);
    const classes = [
      "ndl-ellipsis cell-radio",
      context.data.staticClass,
      context.data.class
    ];
    return (
      <span scoped-cell-radio class={classes} style={style}>
        {props.value}
      </span>
    );
  }
};
</script>

<style lang="less">
// functional 组件的 scoped 样式不知为何不起效
// 这里自定义 scoped 避免样式冲突
.cell-radio[scoped-cell-radio] {
  background: #f3c583;
  border-radius: 24px;
  font-size: 12px;
  padding: 1px 10px;
  min-height: 18px;
}
</style>
