import TYPES from "../constant/node-types";

export class Node {
  constructor(node) {
    Object.assign(
      this,
      {
        nodeId: "",
        nodeType: TYPES.FOLDER,
        nodeName: "",
        nodeIcon: "",
        parentId: "",
        shared: "",
        expand: "",
        editNode: false
      },
      node
    );
    if (this.nodeType === TYPES.FOLDER) this.children = [];
  }
  static from(node) {
    return new Node(node);
  }
}

export class Folder extends Node {
  constructor(folder) {
    super(folder);
    Object.assign(
      this,
      {
        description: "",
        cover: "",
        permission: {}
      },
      folder
    );
    if (!this.cover) this.cover = "images/default_cover1.jpg";
  }
  static from(folder) {
    return new Folder(folder);
  }
}

export default Node;
