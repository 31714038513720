import token from "@/utils/token";
import * as APIAuth from "@/api/auth";
import * as APISpace from "@/api/space";
import * as APIUser from "@/api/user";
import * as Model from "@/model";

export default class UserStore {
  /**
   * 当前用户信息，默认为 null
   * @type {Model.User}
   */
  userInfo = null;
  /**
   * 空间信息，默认为 {}
   * @type {Model.Space}
   */
  spaceInfo = {};
  /**
   * 用户权限列表
   * @type {array}
   */
  permissions = [];
  /**
   * getters
   */
  get isSuperAdmin() {
    return this.userInfo ? this.userInfo.isSuperAdmin : false;
  }
  get spaceId() {
    const userInfo = this.userInfo || {};
    return userInfo.spaceId;
  }
  get spaceName() {
    const spaceInfo = this.spaceInfo || {};
    return spaceInfo.spaceName;
  }
  get permissionMap() {
    const permissions = this.permissions || [];
    return permissions.reduce((obj, item) => ((obj[item] = true), obj), {});
  }
  get enableModules() {
    return this.spaceInfo.enableModules.reduce((map, item) => {
      map[item] = true;
      return map;
    }, {});
  }
  /**
   * actions
   */
  logout() {
    this.userInfo = null;
    APIAuth.logout();
    token.clear();
  }
  async updateUserInfo() {
    try {
      await token.check();
      const { data } = await APIUser.getUserInfo();
      // isSuperAdmin 标识超级管理员身份
      if (!("isSuperAdmin" in data)) {
        data.isSuperAdmin = data.enableCreateSpace;
      }
      token.updateAxiosHeaders(data.spaceId);
      this.userInfo = Model.User.from(data);
      /**
       * ⚠️ 注意事项
       * 请先等待空间加载完成再 return，便于判断空间是否被删除。
       * 请先加载权限列表，某些页面（如用户管理）根据权限列表判断是否允许进入。
       */
      await Promise.all([this.updateSpaceInfo(), this.updatePermissions()]);
      return data;
    } catch (err) {
      return null;
    }
  }
  async updateSpaceInfo() {
    try {
      if (!this.spaceId) {
        return;
      }
      const { data } = await APISpace.getSpace(this.spaceId);
      this.spaceInfo = Model.Space.from(data);
    } catch (err) {
      this.spaceInfo = {};
    }
  }
  async updatePermissions() {
    try {
      if (!this.spaceId) {
        return;
      }
      const params = { spaceId: this.spaceId };
      const { data } = await APIUser.getUserResources(params);
      this.permissions = data.permission;
    } catch (err) {
      this.permissions = [];
    }
  }
  isModuleEnabled(moduleName) {
    const enableModules = this.spaceInfo.enableModules || [];
    return this.isSuperAdmin || enableModules.includes(moduleName);
  }
}
