import Layout from "@/Layout";
import PRIVILEGES from "@/constant/privileges";
import PERMISSIONS from "@/constant/permissions";
import { User, Permission } from "./interceptor";

export default [
  {
    path: "/settings",
    meta: { title: "空间设置" },
    beforeEnter: User[PRIVILEGES.ADMIN],
    component: Layout,
    children: [
      {
        path: "",
        name: "settings",
        meta: { title: "空间设置" },
        redirect: { name: "settings-overview" },
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/views/settings/Settings.vue"
          ),
        children: [
          {
            path: "overview",
            name: "settings-overview",
            meta: { title: "空间信息" },
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "@/views/settings/Overview.vue"
              )
          },
          {
            path: "spacelist",
            name: "settings-spacelist",
            meta: { title: "空间列表" },
            beforeEnter: User[PRIVILEGES.SUPER_ADMIN],
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "@/views/settings/SpaceList.vue"
              )
          },
          {
            path: "history",
            name: "settings-history",
            meta: { title: "操作日志" },
            beforeEnter: User[PRIVILEGES.SUPER_ADMIN],
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "@/views/settings/History.vue"
              )
          },
          {
            path: "definition/:category",
            name: "settings-definition",
            meta: { title: "连接器管理" },
            beforeEnter: Permission[PERMISSIONS.ADMIN],
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "../views/settings/Definition.vue"
              )
          },
          {
            path: "team",
            name: "settings-team",
            meta: { title: "团队管理" },
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "../views/settings/OrgTeam.vue"
              )
          },
          {
            path: "manager",
            name: "settings-manager",
            meta: { title: "空间管理员" },
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "../views/settings/OrgManager.vue"
              )
          }
        ]
      }
    ]
  }
];
