<template>
  <div class="ndl-padding ndl-text-align-center">
    <span class="btn" @click="redirect()">
      <i class="ndl-icon-user"></i> 用户中心
    </span>
    <a-divider type="vertical" style="margin: 0 2em" />
    <span class="btn" @click="logout()">
      <i class="ndl-icon-log-out"></i> 退出登录
    </span>
  </div>
</template>

<script>
import store from "@/store";

export default {
  inheritAttrs: false,
  props: {
    hidePopover: Function
  },
  methods: {
    redirect() {
      this.$router.push("/user/center");
      this.hidePopover();
    },
    logout() {
      store.logout();
      location = "/";
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.btn {
  transition: color 0.2s;
  cursor: pointer;
  &:hover {
    color: @primary-color;
  }
}
</style>
