const Codes = Object.freeze({
  TOKEN_EXPIRED: 42001,
  TOKEN_ILLEGAL: 42002,
  REFRESH_TOKEN_EXPIRED: 42003
});

export function isInvalidToken(errorCode) {
  const code = Number(errorCode);
  return code == Codes.TOKEN_EXPIRED || code == Codes.TOKEN_ILLEGAL;
}

export default Codes;
