import Vue from "vue";
import VueRouter from "vue-router";
import { baseURL } from "@/utils/baseurl";
import store from "@/store";

import routeConnections from "./route-connections";
import routeDatamodel from "./route-datamodel";
import routeSettings from "./route-settings";
import routeUser from "./route-user";
import routeShare from "./route-share";
import routeError from "./route-error";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: baseURL,
  routes: [
    {
      path: "/",
      name: "home",
      beforeEnter(to, from, next) {
        const spaceId = store.spaceId;
        let defaultModule = "user-center";
        if (store.enableModules.connections) {
          defaultModule = "connections";
        } else if (store.enableModules.datamodel) {
          defaultModule = "datamodel";
        }
        const target = spaceId
          ? { name: defaultModule, params: { spaceId } }
          : { name: "new-space" };
        next(target);
      }
    },
    ...routeConnections,
    ...routeDatamodel,
    ...routeSettings,
    ...routeUser,
    ...routeShare,
    ...routeError
  ]
});

function findRoute(route, findFn) {
  // match 到的路由是从上到下（父路由 > 子路由），而 title 优先级为 子路由 > 父路由
  // 因此需要先反转一下数组，因为 find 找到第一个符合条件的元素就会停止遍历
  const matchedRoutes = (route.matched || []).slice().reverse();
  return matchedRoutes.find(findFn);
}

// 判断权限状态
router.beforeEach(async (to, from, next) => {
  let userInfo = store.userInfo;
  /**
   * Case 1: 判断页面是否开放访问，open 为 true 且用户已登陆 可直接放行
   * 注：初始化 vue app 之前会先初始化 store 中的 userInfo
   * 因此能获取到 userInfo 就代表已登陆
   */
  if (findRoute(to, item => item.meta.open)) {
    return next();
  }
  /**
   * Case 2: 需要登陆访问的页面，先等待用户信息、空间信息获取完毕再进入
   * 注：获取不到用户信息说明需要重新登陆
   */
  if (!userInfo) {
    userInfo = await store.updateUserInfo();
  }
  /**
   * Case 3: 该路由或其父路由含 checkSpace 标记，且标记为 true 时，需要检查空间是否被标记删除
   ************************* ⚠️ 注意 *************************
   * 若子路由设置了 checkSpace: false，那么该页面无需检查 space 是否
   * 删除，即子路由的 checkSpace 状态会覆盖父路由的 checkSpace 状态
   * 因此，只能通过 `item => "checkSpace" in item.meta` 来找出最
   * 近一个设置了 checkSpace 标记的路由，不能使用形如：
   * `item => item.meta.checkSpace` 的方法查找
   */
  const predicate = item => "checkSpace" in item.meta;
  const routeWithCheckSpaceFlag = findRoute(to, predicate);
  if (routeWithCheckSpaceFlag && routeWithCheckSpaceFlag.meta.checkSpace) {
    const spaceInfo = store.spaceInfo || {};
    if (spaceInfo.isPreDelete) {
      // 当前用户若是空间主管理员，则跳转空间回收站，否则跳转空间不存在页面
      const name = spaceInfo.isAdmin ? "space-trash" : "space-403";
      return next({ name });
    }
  }
  /**
   * Case 4: 其余所有情况都需要登陆访问，没有 userInfo 就老老实实去登陆页～
   */
  if (!userInfo) {
    return next({ name: "login" });
  }
  next();
});

// 导航结束更新页面标题
router.afterEach(to => {
  const titledRoute = findRoute(to, item => item.meta.title);
  if (titledRoute) document.title = titledRoute.meta.title;
});

export default router;
