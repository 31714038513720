export default class UIStore {
  /* states */
  // 侧边栏悬浮
  float = false;
  // 是否显示侧边栏
  visible = true;

  /* actions */
  // hide() {
  //   this.visible = false;
  // }
  // show() {
  //   this.visible = true;
  // }
}
