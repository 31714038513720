import Error403 from "@/views/error/403";
import Error404 from "@/views/error/404";

export default [
  {
    path: "/space/403",
    name: "space-403",
    meta: { title: "找不到空间", checkSpace: false },
    component: () =>
      import(/* webpackChunkName: "space-trash" */ "@/views/error/Space403.vue")
  },
  {
    path: "/error/403",
    name: "error-403",
    component: Error403
  },
  {
    path: "*",
    name: "error-404",
    component: Error404
  }
];
