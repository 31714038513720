import FilterTypes from "@/constant/filter-types";
import ColumnTypes from "@/constant/column-types";

/**
 * 用于获取要过滤的列的一些必要字段
 * @param {object} column
 * @returns object
 */
export function pickAttr(column) {
  const meta = {};
  meta.columnId = column.columnId || null;
  meta.columnType = column.columnType || null;
  meta.columnName = column.columnName || null;
  meta.tableId = column.tableId || null;
  meta.aggType = column.aggType || null;
  meta.formatType = column.formatType || null;
  return meta;
}

/**
 * 旧系统过滤器结构大致为
 * {
 *  column: "",
 *  table: "",
 *  datasourceId: "",
 *  formatType: "",
 *  filterType: "",
 *  value: []
 * }
 * 转换为现在的结构
 * {
 *  columnId: "",
 *  operator: "",
 *  value: "",
 *  values: [],
 *  startValue: "",
 *  endValue: "",
 * }
 */
const typeMap = (function() {
  const map = {
    atobMap: {},
    btoaMap: {},
    atob(key) {
      return this.atobMap[key];
    },
    btoa(key) {
      return this.btoaMap[key];
    }
  };
  function define(a, b) {
    map.atobMap[a] = b;
    map.btoaMap[b] = a;
  }
  define("=", FilterTypes.EQUAL);
  define("≠", FilterTypes.NOT_EQUAL);
  define("CT", FilterTypes.CONTAIN);
  define("NT", FilterTypes.NOT_CONTAIN);
  define("[a,b]", FilterTypes.BETWEEN);
  define("(a,b]", FilterTypes.BETWEEN_NOT_LEFT);
  define("[a,b)", FilterTypes.BETWEEN_NOT_RIGHT);
  define("(a,b)", FilterTypes.BETWEEN);
  return map;
})();

export default class Filter {
  constructor(filter) {
    Object.assign(
      this,
      {
        columnId: null,
        columnType: ColumnTypes.text,
        operator: FilterTypes.EQUAL,
        value: null,
        values: [],
        startValue: null,
        endValue: null,
        dateShortcut: "date",
        dateShortcutUnit: "day"
      },
      filter
    );
  }
  /**
   * 把旧过滤器转换为新格式
   */
  static parse(legacyFilter) {
    const { value, filterType } = legacyFilter || {};
    const filter = new Filter();
    const values = [].concat(value || []);
    filter.operator = typeMap.atob(filterType) || FilterTypes.EQUAL;
    filter.value = values[0];
    filter.values = values;
    filter.startValue = values[0];
    filter.endValue = values[1];
    // 处理空值和非空值
    const isNULL = filter.values.join("") === "#NULL";
    if (isNULL && filter.operator === FilterTypes.EQUAL) {
      filter.operator = FilterTypes.NULL;
    }
    if (isNULL && filter.operator === FilterTypes.NOT_EQUAL) {
      filter.operator = FilterTypes.NOT_NULL;
    }
  }
  /**
   * 转换为旧系统过滤器格式
   */
  static format(filter = {}) {
    let value = [];
    let filterType = typeMap.btoa(filter.operator);
    const columnType = filter.columnType;
    switch (filter.operator) {
      // 文本类型的等于不等于支持多选，从 values 取值
      case FilterTypes.EQUAL:
      case FilterTypes.NOT_EQUAL:
        // eslint-disable-next-line
        const isSingleValue = [ColumnTypes.number, ColumnTypes.date].includes(columnType);
        if (isSingleValue) value = [].concat(filter.value);
        else value = [].concat(filter.values || []);
        break;
      case FilterTypes.CONTAIN:
      case FilterTypes.NOT_CONTAIN:
      case FilterTypes.LESS_THAN:
      case FilterTypes.GREATER_THAN:
        value = [].concat(filter.value);
        break;
      case FilterTypes.BETWEEN:
      case FilterTypes.BETWEEN_NOT_LEFT:
      case FilterTypes.BETWEEN_NOT_RIGHT:
      case FilterTypes.BETWEEN_NOT_BOTH:
        value = [filter.startValue, filter.endValue];
        break;
      case FilterTypes.NULL:
        value = ["#NULL"];
        filterType = typeMap.btoa(FilterTypes.EQUAL);
        break;
      case FilterTypes.NOT_NULL:
        filterType = typeMap.btoa(FilterTypes.NOT_EQUAL);
        value = ["#NULL"];
        break;
    }
    return {
      ...filter,
      filterType,
      value,
      shortcut: filter.dateShortcut
    };
  }
  static from(filter) {
    return new Filter(filter);
  }
  static reset(filter) {
    Object.assign(filter, {
      value: null,
      values: [],
      startValue: null,
      endValue: null,
      dateShortcut: "date",
      dateShortcutUnit: "day"
    });
  }
}
