export default class Space {
  spaceId = "";
  spaceName = "";
  spaceLogo = "";
  ownerName = "";
  ownerAvatar = "";
  ownerMemberId = "";
  ownerMobile = "";
  ownerMail = "";
  createTime = "";
  updateTime = "";
  createBy = "";
  updateBy = "";
  // 空间是否放入回收站
  isPreDelete = false;
  preDelTime = "";
  enableModules = [];
  constructor(space) {
    Object.assign(this, space);
  }
  static from(space) {
    return new Space(space);
  }
}
