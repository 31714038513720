export default {
  // 单元格默认宽度
  cellWidth: 180,
  minCellWidth: 90,
  // 索引列单元格宽度
  indexCellWidth: 60,
  // 单元格高度
  cellHeight: 32,
  // 表头单元格高度
  headerCellHeight: 36,
  groupGutter: 16,
  groupCellHeight: 48,
  // 滚动预留在可视区外的列数
  viewportBufferColumn: 0,
  // 滚动预留在可视区外的行数
  viewportBufferRow: 1,
  // 在可视区 ±100px 内的分组视为可见
  viewportBufferGroupOffset: 100
};

export const colors = [
  // row 1
  ["rgb(85, 134, 255)", "#fff"],
  ["rgb(85, 205, 255)", "#fff"],
  ["rgb(48, 194, 139)", "#fff"],
  ["rgb(82, 196, 27)", "#fff"],
  ["rgb(255, 235, 58)"],
  ["rgb(255, 171, 0)", "#fff"],
  ["rgb(255, 122, 0)", "#fff"],
  ["rgb(255, 112, 139)", "#fff"],
  ["rgb(227, 62, 56)", "#fff"],
  ["rgb(50, 50, 50)", "#fff"],
  // row 2
  ["rgb(119, 158, 255)", "#fff"],
  ["rgb(119, 215, 255)", "#fff"],
  ["rgb(89, 206, 162)", "#fff"],
  ["rgb(117, 208, 73)", "#fff"],
  ["rgb(255, 239, 97)", "#fff"],
  ["rgb(255, 188, 51)", "#fff"],
  ["rgb(255, 149, 51)", "#fff"],
  ["rgb(255, 141, 162)", "#fff"],
  ["rgb(233, 101, 96)", "#fff"],
  ["rgb(80, 80, 80)", "#fff"],
  // row 3
  ["rgb(153, 182, 255)"],
  ["rgb(153, 225, 255)"],
  ["rgb(131, 218, 185)"],
  ["rgb(151, 220, 118)"],
  ["rgb(255, 243, 137)"],
  ["rgb(255, 205, 102)"],
  ["rgb(255, 175, 102)"],
  ["rgb(255, 169, 185)"],
  ["rgb(238, 139, 136)"],
  ["rgb(110, 110, 110)", "#fff"],
  // row 4
  ["rgb(187, 207, 255)"],
  ["rgb(187, 235, 255)"],
  ["rgb(172, 231, 209)"],
  ["rgb(186, 231, 164)"],
  ["rgb(255, 247, 176)"],
  ["rgb(255, 221, 153)"],
  ["rgb(255, 202, 153)"],
  ["rgb(255, 198, 209)"],
  ["rgb(244, 178, 175)"],
  ["rgb(140, 140, 140)", "#fff"],
  // row 5
  ["rgb(221, 231, 255)"],
  ["rgb(221, 245, 255)"],
  ["rgb(214, 243, 232)"],
  ["rgb(220, 243, 209)"],
  ["rgb(255, 251, 216)"],
  ["rgb(255, 238, 204)"],
  ["rgb(255, 228, 204)"],
  ["rgb(255, 226, 232)"],
  ["rgb(249, 216, 215)"],
  ["rgb(170, 170, 170)", "#fff"]
];

export function uniqueColorBy(value) {
  const str = String(value || "");
  let sumCode = 0;
  for (let i = 0, len = str.length; i < len; i += 1) {
    sumCode += str.charCodeAt(i);
  }
  return sumCode % colors.length;
}
