import Vue from "vue";
import {
  message,
  notification,
  AutoComplete,
  Alert,
  Avatar,
  Badge,
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Icon,
  Input,
  InputNumber,
  LocaleProvider,
  Menu,
  Modal,
  Popconfirm,
  Popover,
  Progress,
  Radio,
  Row,
  Select,
  Spin,
  // Statistic,
  Steps,
  Switch,
  Tabs,
  Tag,
  TimePicker,
  Tooltip,
  Upload,
  Drawer,
  Skeleton,
  ConfigProvider,
  Empty,
  Result,
  Pagination,
  Table
} from "ant-design-vue";

Vue.prototype.$message = message;
Vue.prototype.$notification = notification;
Vue.prototype.$info = Modal.info;
Vue.prototype.$success = Modal.success;
Vue.prototype.$error = Modal.error;
Vue.prototype.$warning = Modal.warning;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$destroyAll = Modal.destroyAll;

const components = [
  AutoComplete,
  Alert,
  Avatar,
  Badge,
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Icon,
  Input,
  InputNumber,
  LocaleProvider,
  Menu,
  Modal,
  Popconfirm,
  Popover,
  Progress,
  Radio,
  Row,
  Select,
  Spin,
  Steps,
  Switch,
  Skeleton,
  Tabs,
  Tag,
  Tooltip,
  TimePicker,
  Upload,
  Drawer,
  ConfigProvider,
  Empty,
  Result,
  Table,
  Pagination
];

components.map(function(component) {
  Vue.use(component);
});
