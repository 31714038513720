import Axios from "axios";
import JSON2FormData from "json-form-data";
import { interceptor } from "./index";
import token from "@/utils/token";
import resolveURL from "@/utils/baseurl";

const axios = Axios.create({
  baseURL: resolveURL("/api/uua"),
  headers: {
    Authorization: token.defaultAuthorization,
    "Content-Type": "multipart/form-data"
  }
});

// auth 相关接口无需认证，用默认拦截器处理一下返回的数据结果便可
interceptor.default(axios);

export function login(data) {
  return axios.post("/auth", JSON2FormData(data));
}

export function logout() {
  const headers = { Authorization: token.value() };
  return axios.get("/logout", { headers });
}

export default axios;
