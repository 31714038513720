import Axios from "axios";
import JSON2FormData from "json-form-data";
import { interceptor } from "./index";
import resolveURL from "@/utils/baseurl";

const axios = Axios.create({ baseURL: resolveURL("/api/user") });

interceptor.auth(axios);

export function updateUser(data) {
  return axios.post("/update", data);
}

export function createUser(data) {
  return axios.post("/create", data);
}

export function updatePassword(data) {
  const headers = { "Content-Type": "multipart/form-data" };
  return axios.post("/update/pwd", JSON2FormData(data), { headers });
}

export function getUserInfo(params) {
  return axios.get("/me", { params });
}

export function getUserResources(params) {
  return axios.get("/my/resource", { params });
}

export function updateApiToken(userId) {
  return axios.put(`/updateApiToken/${userId}`);
}

export default axios;
