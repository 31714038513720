import ColumnTypes from "./column-types";

// 过滤器操作符类型
const FilterTypes = Object.freeze({
  EQUAL: "equal",
  NOT_EQUAL: "not_equal",
  LESS_THAN: "less_than",
  LESS_EQUAL: "less_equal",
  GREATER_THAN: "greater_than",
  GREATER_EQUAL: "greater_equal",
  BETWEEN: "between",
  BETWEEN_NOT_LEFT: "between_not_left",
  BETWEEN_NOT_RIGHT: "between_not_right",
  BETWEEN_NOT_BOTH: "between_not_both",
  CONTAIN: "contain",
  NOT_CONTAIN: "not_contain",
  NULL: "is_null",
  NOT_NULL: "not_null",
  // 一般只用于 case when 的 else 规则
  ELSE: "else"
});

export default FilterTypes;

export const DateTimeShortcuts = Object.freeze([
  { value: "today", label: "今天" },
  { value: "yesterday", label: "昨天" },
  { value: "tomorrow", label: "明天" },
  { value: "a-week-ago", label: "一周前" },
  { value: "a-month-ago", label: "一月前" }
]);

export const DateTimeRangeShortcuts = Object.freeze([
  { value: "last-week", label: "上周" },
  { value: "last-month", label: "上月" },
  { value: "this-week", label: "本周" },
  { value: "this-month", label: "本月" },
  { value: "this-year", label: "今年" },
  { value: "last-year", label: "去年" }
]);

export const DynamicShortcuts = Object.freeze([
  { value: "latest", label: "最近 N 天（月）" },
  { value: "past", label: "过去 N 天（月）" },
  { value: "future", label: "将来 N 天（月）" }
]);

export const DynamicShortcutUnits = Object.freeze([
  { value: "day", label: "天" },
  { value: "month", label: "月" }
]);

export const ColumnFilterTypes = {
  [ColumnTypes.text]: [
    { value: FilterTypes.EQUAL, label: "等于" },
    { value: FilterTypes.NOT_EQUAL, label: "不等于" },
    { value: FilterTypes.CONTAIN, label: "包含" },
    { value: FilterTypes.NOT_CONTAIN, label: "不包含" },
    { value: FilterTypes.NULL, label: "为空" },
    { value: FilterTypes.NOT_NULL, label: "非空" }
  ],
  [ColumnTypes.number]: [
    { value: FilterTypes.EQUAL, label: "=" },
    { value: FilterTypes.NOT_EQUAL, label: "≠" },
    { value: FilterTypes.LESS_THAN, label: "<" },
    { value: FilterTypes.LESS_EQUAL, label: "≤" },
    { value: FilterTypes.GREATER_THAN, label: ">" },
    { value: FilterTypes.GREATER_EQUAL, label: "≥" },
    { value: FilterTypes.BETWEEN, label: "[a,b]" },
    { value: FilterTypes.BETWEEN_NOT_LEFT, label: "(a,b]" },
    { value: FilterTypes.BETWEEN_NOT_RIGHT, label: "[a,b)" },
    { value: FilterTypes.BETWEEN_NOT_BOTH, label: "(a,b)" },
    { value: FilterTypes.NULL, label: "为空" },
    { value: FilterTypes.NOT_NULL, label: "非空" }
  ],
  [ColumnTypes.date]: [
    { value: FilterTypes.EQUAL, label: "等于" },
    { value: FilterTypes.NOT_EQUAL, label: "不等于" },
    { value: FilterTypes.LESS_EQUAL, label: "早于" },
    { value: FilterTypes.GREATER_EQUAL, label: "晚于" },
    { value: FilterTypes.NULL, label: "为空" },
    { value: FilterTypes.NOT_NULL, label: "非空" }
  ]
};
