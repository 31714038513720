import axios from "axios";
import token from "@/utils/token";
import { isInvalidToken } from "@/constant/error-code";

export const interceptor = (function() {
  const onSuccess = response => ({ data: response.data || {}, response });
  const onError = error => {
    const response = error.response;
    const data = (response && response.data) || {};
    return Promise.reject({ error, response, data });
  };
  return {
    onSuccess,
    onError,
    default(instance) {
      instance.interceptors.response.use(onSuccess, onError);
    },
    auth(instance) {
      async function onAuthError(error) {
        const response = error.response;
        const data = (response && response.data) || {};
        // 非 token 错误或者无 refresh_token，不重新获取 token
        if (!isInvalidToken(data.code)) {
          throw { error, response, data };
        }
        try {
          await token.refresh();
          // 重试请求
          error.config.headers.Authorization = token.value();
          return axios(error.config);
        } catch {
          throw { error, response, data };
        }
      }
      instance.interceptors.response.use(onSuccess, onAuthError);
    }
  };
})();

// 设置全局请求 Authorization
token.updateAxiosHeaders();

export default axios;
