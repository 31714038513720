<template>
  <a-popover
    v-model="visible"
    v-surgery
    destroy-tooltip-on-hide
    trigger="click"
    placement="rightBottom"
    :get-popup-container="getPopupContainer"
  >
    <Avatar
      circle
      size="small"
      :border="false"
      :color="userInfo.memberId | colorOf"
      :src="userInfo.avatar"
      :text="userInfo.memberName"
    />
    <div slot="content" style="width: 280px">
      <Header :hide-popover="hide" @click="gotoUserCenter()" />
      <Body />
      <Footer :hide-popover="hide" />
    </div>
  </a-popover>
</template>

<script>
import store from "@/store";
import Avatar, { colorOf } from "@/components/avatar";
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";

export default {
  components: { Avatar, Header, Body, Footer },
  props: {
    getPopupContainer: Function
  },
  directives: {
    // 移除三角形和 padding
    surgery: {
      update(el, binding, node) {
        setTimeout(() => {
          /**
           * @type {{ getPopupDomNode: () => HTMLElement }}
           */
          const vc = node && node.componentInstance;
          const dom = vc && vc.getPopupDomNode();
          if (!dom) {
            return;
          }
          // 移除三角形
          const arrow = dom.querySelector(".ant-popover-arrow");
          arrow && arrow.remove();
          // 移除 .ant-popover-inner-content 的 padding
          const content = dom.querySelector(".ant-popover-inner-content");
          if (content) {
            content.style.padding = 0;
          }
        });
      }
    }
  },
  data() {
    return { visible: false };
  },
  computed: {
    userInfo() {
      return store.userInfo || {};
    }
  },
  filters: { colorOf },
  methods: {
    hide() {
      this.visible = false;
    }
  }
};
</script>
