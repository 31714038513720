import { random } from "@/utils/string";
import Types from "../define/column-types";
import ColumnOption from "./column-option";

export default class Column {
  constructor(column, defaultOpts) {
    Object.assign(
      this,
      {
        columnId: "col" + random(9),
        columnName: "",
        columnIndex: 0,
        columnType: Types.text,
        columnWidth: null,
        columnLeft: 0,
        columnOptions: {},
        visible: true,
        fixed: false
      },
      column
    );
    const options = Object.assign({}, this.columnOptions, defaultOpts);
    this.columnOptions = new ColumnOption(options);
  }
  static from(column, opts) {
    return new Column(column, opts);
  }
  static clone(column) {
    const newColumn = { ...column };
    delete newColumn.columnId;
    return new Column(newColumn);
  }
}
